(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-marketplace-groups/assets/javascripts/marketplace-groups.js') >= 0) return;  svs.modules.push('/components/payment/middleware-marketplace-groups/assets/javascripts/marketplace-groups.js');

'use strict';

const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  common
} = svs.components.payment;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  services
} = svs.components.payment.middleware;
const {
  hasPermission,
  PERMISSIONS
} = svs.components.marketplaceData.auth.roles;
const isFollowing = group => Object.prototype.hasOwnProperty.call(group, 'currentUserRoleInGroup');
const marketplaceGroups = _ref => {
  let {
    payment
  } = _ref;
  return async next => {
    const payableItems = payment.getPayableItems();
    const fraction = payableItems.find(payable => payable.activatedDrawId);
    if (!fraction) {
      return next(new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.NOT_FRACTION
      }));
    }
    let group;
    try {
      group = await services.fetchGroup(fraction.groupId);
    } catch (error) {
      return next(error);
    }
    switch (group.groupType) {
      case groupTypes.PRIVATE:
        break;
      case groupTypes.RETAILER:
        {
          if (!isFollowing(group)) {
            let response;
            const {
              token
            } = group.token;
            try {
              response = await services.joinTeam(token);
            } catch (error) {
              return next(error);
            }
            common.logger.info("User added to group with id: ".concat(response.AddMemberReply.groupId));
          } else if (!hasPermission(group.currentUserRoleInGroup, PERMISSIONS.PURCHASE_FRACTION)) {
            return next(new MiddlewarePaymentError({
              extraData: {
                message: 'User does not have permission to purchase fraction'
              }
            }));
          }
          break;
        }
      default:
        return next(new MiddlewarePaymentError({
          extraData: {
            message: "Group type \"".concat(group.groupType, "\" not supported")
          }
        }));
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.marketplaceGroups', marketplaceGroups);

 })(window);