(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-marketplace-groups/assets/javascripts/services/join-team.js') >= 0) return;  svs.modules.push('/components/payment/middleware-marketplace-groups/assets/javascripts/services/join-team.js');

'use strict';

const {
  jupiterFetch
} = svs.core;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const joinTeam = async token => {
  try {
    const response = await jupiterFetch.fetch("/marketplace/1/invitationlinks/".concat(token), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    });
    const data = await response.json();
    if (!response.ok) {
      var _data$error, _data$error2;
      throw new MiddlewarePaymentError({
        clientCode: MiddlewarePaymentError.ErrorCodes.GROUP_JOIN_ERROR,
        code: (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.code,
        extraData: {
          message: (_data$error2 = data.error) === null || _data$error2 === void 0 ? void 0 : _data$error2.message
        }
      });
    }
    return data;
  } catch (error) {
    throw new MiddlewarePaymentError({
      clientCode: MiddlewarePaymentError.ErrorCodes.GROUP_JOIN_ERROR,
      code: error === null || error === void 0 ? void 0 : error.code,
      extraData: {
        message: error === null || error === void 0 ? void 0 : error.message
      }
    });
  }
};
setGlobal('svs.components.payment.middleware.services.joinTeam', joinTeam);

 })(window);